// 基准大小

const baseSize = 16

// 设置 rem 函数

function setRem() {

  // 页面宽度 1920 的缩放比例。

  const scale = document.documentElement.clientWidth / 1920

  // 设置页面根节点字体大小

  document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px'

}

// 初始化

setRem()

// 改变窗口大小时重新设置 rem

window.onresize = function () {

  setRem()

}