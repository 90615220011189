// 应用入口js
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import * as echarts from 'echarts';

import '@/styles/common.scss';

import './utils/rem'
const Base64 = require('js-base64').Base64

import moment from 'moment' //导入文件
moment.locale('zh-cn'); //需要汉化
Vue.prototype.$moment = moment; //赋值使用

import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)

import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsDrilldown from 'highcharts/modules/drilldown';
import Highcharts3d from 'highcharts/highcharts-3d';
 
//高德地图
import VueAMap from 'vue-amap'
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
	key: 'b007e9d57294c9825e60a01361f0ef4e',
	// 插件集合
	plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Polygon', 'AMap.Scale', 'AMap.OverView',
		'AMap.ToolBar', 'AMap.Geolocation',
		'AMap.MapType', 'AMap.PolyEditor', 'AMap.GeometryUtil', 'AMap.DistrictSearch', 'AMap.Geocoder',
		'AMap.Icon', 'AMap.MouseTool'
	],
	v: '1.4.4',
	uiVersion: "1.1"
})
window._AMapSecurityConfig = {
	securityJsCode: "fe53e97e2fb53e1f3cdb717dea1dc333",
};

// 解决地图刷新显示不出来
const amapKeys = Object.keys(localStorage).filter(key => key.match(/^_AMap_/))
amapKeys.forEach(key => {
	// console.log(key)
	localStorage.removeItem(key)
})

HighchartsMore(Highcharts)
HighchartsDrilldown(Highcharts);
Highcharts3d(Highcharts);

Vue.use(ElementUI)
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false;

new Vue({
	router,
	store,
	Base64,
	render: (h) => h(App),
}).$mount("#app");