<template>
  <div class="page">
    <!-- 框架顶部 -->
    <div class="box_4 flex-row justify-between">
      <div class="top_left align-center">
        <img class="label_1" src="@/assets/nowtime.png" />
        <span class="text_1">{{ dateFormat(newTime) }}</span>
      </div>
      <span class="top_title">眉县四改五提升示范园项目展示中心</span>
      <div class="top_right align-center justify-end ">
        <!-- <div class="sel_province">
          <el-select v-model="value" placeholder="请选择" size="small" clearable>
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div> -->
        <img class="label_2" src="@/assets/login_p.png" />
        <span class="text_3">{{ username ? username : '监管员' }}</span>
        <div class="box_5 flex-col"></div>
        <img
            class="label_3"
            referrerpolicy="no-referrer"
            src="@/assets/largeScreen/sc (13).png"
            @click="closeHandle"
          />
      </div>
    </div>
    <!-- 主页面框架 -->
    <div class="main-container">
      <!-- 底部菜单列表 
      <sidebar class="sidebar-container" />-->
      <!-- 主页面 -->
      <app-main />
      <!-- <router-view /> -->
    </div>
    <!-- 底部菜单 -->
    <div class="box_13 flex-row justify-between">
      <div :class="textActive == 'productionEntity' ? 'routerText activeStyle' : 'routerText'" @click="toPages('/productionEntity')">
        <span class="text_title">产业概览</span>
      </div>
      <div :class="textActive == 'agriculturalDetection' ? 'routerText activeStyle' : 'routerText'" @click="toPages('/agriculturalDetection')">
        <span class="text_title">农情监测</span>
      </div>
      <div :class="textActive == 'remoteControl' ? 'routerText activeStyle' : 'routerText'" @click="toPages('/remoteControl')">
        <span class="text_title">远程控制</span>
      </div>
      <div :class="textActive == 'digitalProduction' ? 'routerText activeStyle' : 'routerText'" @click="toPages('/digitalProduction')">
        <span class="text_title">数字化生产</span>
      </div>
      <div :class="textActive == 'productionGuidance' ? 'routerText activeStyle' : 'routerText'" @click="toPages('/productionGuidance')">
        <span class="text_title">生产指导</span>
      </div>
    </div>
   
  </div>
</template>
<script>
import AppMain from './components/AppMain.vue'
export default {
  name: "Layout",
  components: {
    AppMain
  },
  props: {
    msg: String,
  },
  watch: {
      $route(){
        // console.log(this.$route.path)
        this.changeRoute();
      }
  },
  data() {
    return {
      newTime: '',
      name: '',
      username: '',
      options: [{
          value: '选项1',
          label: '眉县1'
        }, {
          value: '选项2',
          label: '眉县2'
        }, {
          value: '选项3',
          label: '眉县3'
        }],
        value: '',
        key:'',
        textActive: 'productionEntity' //路由标识
    };
  },
  created(){
    this.name = localStorage.getItem("name")
    this.username = localStorage.getItem("username")
    // this.$nextTick(() => {
    //     this.name = localStorage.getItem("name")
    //   })
  },
  mounted(){
    this.changeRoute();
     this.getNowTime();//进入页面调用该方法获取当前时间
        clearInterval(myTimeDisplay );//销毁之前定时器
        var myTimeDisplay = setInterval(() => {
           this.getNowTime(); //每秒更新一次时间
        }, 1000); 
  },
  methods: {
    getNowTime() {
            var date = new Date();
            var time = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + this.addZero(date.getMinutes()) + ':' + date.getSeconds();
            this.newTime = time;
        },
        addZero(s) {
            return s < 10 ? ('0' + s) : s;
        },
    dateFormat () {
    	var date = new Date()
        var year = date.getFullYear()
      
        var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
        var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
        var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
        var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
        var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
        let week = date.getDay() // 星期
        let weekArr = [ '星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六' ]
        // 拼接 时间格式处理
        return year + '年' + month + '月' + day + '日 ' + hours + ':' + minutes + ':' + seconds + ' ' + weekArr[week]
    },
    closeHandle(){
        this.$confirm('确认退出？')
          .then(_ => {
            this.$router.push({
              path: '/login',
            }, () => {})
            sessionStorage.clear()
          }).catch(_ => {});
        
    },
    toPages(index){
      //console.log('routes=====',index)
      // this.key = index
      // this.$router.push({path: index,})
      this.$router.push({
        path: index,
      }, () => {})
    },
    changeRoute(){
      if (this.$route.path == '/productionEntity'){
          this.textActive = 'productionEntity'
      } else if(this.$route.path == '/productionGuidance') {
        this.textActive = 'productionGuidance'
      } else if (this.$route.path == '/agriculturalDetection'){
        this.textActive = 'agriculturalDetection'
      } else if (this.$route.path == '/remoteControl'){
        this.textActive = 'remoteControl'
      } else if (this.$route.path == '/digitalProduction'){
        this.textActive = 'digitalProduction'
      }
    }
  },
  // 销毁时清除计时器
 beforeDestroy: function () {
    if (this.timer) {
      clearInterval(this.timer)
    }
}
};
</script>

<style scoped lang="scss">
@import "~@/styles/index.scss";

  .page{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: url(@/assets/largeScreen/bg.png)
        100% no-repeat;
      background-size: 100% 100%;
      .box_4 {
      background: url(@/assets/topbg.png)
        100% no-repeat;
      background-size: 100% 100%;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 107px;
      .top_left{
        width: 30%;
        height: 45px;
        padding: 0 30px;
        .label_1 {
          width: 21px;
          height: 21px;
          margin-right: 10px;
        }
        .text_1 {
          overflow-wrap: break-word;
          color: rgba(61, 239, 255, 1);
          font-size: 18px;
          font-family: DIN-Medium;
          font-weight: 500;
          text-align: left;
          white-space: nowrap;
        }
      }
      
      .top_title{
        height: 90px;
        line-height: 90px;
        font-size: 25px;
        font-family: Microsoft YaHei;
        font-weight: 700;
        color: #FFFFFF;
      }
     
      .top_right{
        width: 30%;
        height: 45px;
        padding: 0 30px;
        .sel_province {
          width: 100px;
          ::v-deep .el-input--small .el-input__inner{
            height: 32px;
            line-height: 32px;
            border: 0;
            background-color: rgba(255,255,255,0);
            color: #3DEFFF;
            font-size: 16px;
            font-family: PingFang-SC-Medium;
            font-weight: 500;
          }
          ::v-deep .el-select .el-input .el-select__caret {
            color: $color-theme;
            font-size: 14px;
            transition: transform .3s;
            transform: rotateZ(180deg);
            cursor: pointer;
          }
          ::v-deep .el-select-dropdown__item.selected {
            color: #3DEFFF;
            font-weight: 700;
          }
        }
        .label_2 {
          width: 22px;
          height: 22px;
          margin-left: 60px;
        }
        .text_3 {
          color: rgba(61, 239, 255, 1);
          font-size: 18px;
          font-family: PingFang-SC-Medium;
          font-weight: 500;
          text-align: left;
          white-space: nowrap;
          line-height: 24px;
          margin: 0 20px 0 10px;
        }
        .box_5 {
          background-color: rgba(37, 169, 246, 1);
          width: 1px;
          height: 18px;
          margin-right: 20px;
        }
        .label_3 {
          width: 21px;
          height: 21px;
          cursor: pointer;
        }
      }
    }
    .main-container{
      position: absolute;
      left: 0;
      top: 107px;
      width: 100%;
      height: calc(100% - 107px - 78px);
      overflow: hidden;
    }
    .box_13 {
      width: 100%;
      height: 78px;
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 8px 15px;
      margin: 0;
      .routerText{
        height: 100%;
        background: url('@/assets/largeScreen/textBg.png') 100% no-repeat;
        background-size: 100% 100%;
        width: calc(20% - 30px);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .text_title{
          color: #fff;
          font-size: 20px;
          font-family: PingFang-SC-Medium;
          font-weight: 500;
        }
      }
      .activeStyle{
        background: url('@/assets/largeScreen/textActiveBg.png') 100% no-repeat;
        background-size: 100% 100%;
        .text_title{
          color: #3DEFFF !important;
        }
      }
    } 
  }

</style>
