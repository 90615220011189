import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout";
// import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "home",
  //   component: Layout,
  //   children: [
  //     {
  //       path: "/",
  //       name: "生产主体",
  //       component: () => import("@/views/productionEntity/index"),
  //       meta: { title: "生产主体", icon: "dashboard" },
  //     },
  //   ],
  // },
  {
    path: '/',
    redirect: '/login'//重定向
  },
  {
    path: "/productionEntity",
    name: "产业概览",
    component: Layout,
    children: [
      {
        path: "/productionEntity",
        name: "产业概览",
        component: () => import("@/views/productionEntity/index"),
        meta: { title: "产业概览", icon: "dashboard" },
      },
    ],
  },
  {
    path: "/agriculturalDetection",
    name: "农情监测",
    component: Layout,
    children: [
      {
        path: "/agriculturalDetection",
        name: "农情监测",
        component: () => import("@/views/agriculturalDetection/index"),
        meta: { title: "农情监测", icon: "dashboard" },
      }
    ],
  },
  {
    path: "/remoteControl",
    name: "远程控制",
    component: Layout,
    children: [
      {
        path: "/remoteControl",
        name: "远程控制",
        component: () => import("@/views/remoteControl/index"),
        meta: { title: "远程控制", icon: "dashboard" },
      }
    ],
  },
  {
    path: "/digitalProduction",
    name: "数字化生产",
    component: Layout,
    children: [
      {
        path: "/digitalProduction",
        name: "数字化生产",
        component: () => import("@/views/digitalProduction/index"),
        meta: { title: "数字化生产", icon: "dashboard" },
      }
    ],
  },
  {
    path: "/productionGuidance",
    name: "生产指导",
    component: Layout,
    children: [
      {
        path: "/productionGuidance",
        name: "生产指导",
        component: () => import("@/views/productionGuidance/index"),
        meta: { title: "生产指导", icon: "dashboard" },
      }
    ],
    // component: () =>
    //   import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  }, 
  ///////////////////// 村级一张图 ////////////////
   {
    path: "/villageLevel",
    name: "村级一张图",
    component: () => import("@/views/villageLevel/index")
  },
    ///////////////////// 登录 ////////////////
    {
      path: "/login",
      name: "登录",
      component: () => import("@/views/login/index")
    },
];

const router = new VueRouter({
  routes,
});

export default router;
